<!--CompositeChat:包含回复的评论 2024/07/20 18:23:13 huangmx-->

<template>
  <div class="composite-chat">
    <chat :item="item" @update-reply="updateReply">
      <div v-if="replyList.length > 0">
        <chat v-for="(reply, index) in replyList" :item="reply" :key="reply.id + '_' + index"
          @update-reply="updateReply"></chat>
      </div>
    </chat>
    <div class="chat-more" v-if="remindReplayCount > 0">
      <el-button type="primary" @click="showMore" plain round>{{ $t('lookMoreBefore') }} {{ remindReplayCount }} {{
      $t('lookMoreAfter') }}</el-button>
    </div>
    <div class="chat-bottom-line"></div>
  </div>
</template>
<script>
import Chat from './Chat.vue'
import { commentApi } from '@/utils/api'
export default {
  name: 'CompositeChat',
  components: {
    Chat
  },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      remindReplayCount: 0,
      replyList: [],
      currentPage: 1
    }
  },
  created () {
    this.remindReplayCount = this.item.childNum
  },
  methods: {
    showMore: function () {
      // 请求接口
      commentApi.getReplyCommentList(this.item.groupKey).then(resp => {
        if (resp.httpStatus === 200) {
          console.log(resp.data)
          this.replyList = resp.data
        }
      })
      this.remindReplayCount = this.item.replyCount - this.replyList.length
    },
    updateReply: function () {
      this.showMore()
      this.$emit('update-reply')
    }
  }
}
</script>

<style scoped>
.composite-chat {
  margin-top: 20px;
}

.chat-more {
  margin-top: 5px;
  text-align: center;
}

.chat-bottom-line {
  margin-top: 10px;
  border-bottom: 1px solid rgb(234, 234, 234);
}
</style>
