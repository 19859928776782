<!--PostCommentInput:评论输入 2024/07/14 17:51:43 YuAng-->

<template>
  <div class="post-comment-input">
    <input-chat @submit-comment="submitComment"></input-chat>
  </div>
</template>
<script>
import InputChat from '../../components/InputChat.vue'
export default {
  name: 'PostCommentInput',
  components: { InputChat },
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      tip: 'Hi, PostCommentInput'
    }
  },
  created () {
    // 请求接口
  },
  mounted () {
    // 更新Dom
  },
  methods: {
    submitComment: function (comment) {
      this.$emit('submit-comment', comment)
    }
  }
}
</script>

<style scoped>
.xx-PostCommentInput {
  color: brown;
}
</style>
