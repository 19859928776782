<!--Report:举报 2024/07/21 12:44:17 huangmx-->

<template>
  <div class="report">
    <el-dialog :title="$t('report')" :visible.sync="localVisible" @close="handleClose" :close-on-click-modal="false"
      :close-on-press-escape="false" width="712px">
      <div>
        <p class="mb-10 mt-10">{{ $t('reportType') }}</p>
        <el-radio-group v-model="form.reportType" class="mb-15">
          <el-radio v-for="(item, index) in reportTypes" :key="index" :label="item.value">{{ item.label }}</el-radio>
        </el-radio-group>
        <p class="mb-10">{{ $t('desc') }}</p>
        <el-input type="textarea" v-model="form.reason" :placeholder="$t('reportTip')" :rows="5"></el-input>
      </div>
      <div class="report-btn">
        <el-button type="primary" size="mini" @click="submitReport">{{ $t('okReport') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { reportApi } from '@/utils/api'
export default {
  name: 'Report',
  props: {
    visible: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    targetType: {
      type: String,
      default: function () {
        return 'COMMENT' // USER ARTICLE COMMENT PRIVATE_MESSAGE
      }
    },
    targetId: {
      type: String,
      default: function () {
        return ''
      }
    }
  },
  data () {
    return {
      localVisible: false,
      reportTypes: [{
        label: '垃圾营销',
        value: 'SPAM_MARKETING'
      }, {
        label: '淫秽色情',
        value: 'OBSCENE'
      }, {
        label: '不实消息',
        value: 'FALSE_INFO'
      }, {
        label: '人身攻击',
        value: 'PERSONAL_ATTACK'
      }, {
        label: '违法信息',
        value: 'ILLEGAL_INFO'
      }, {
        label: '其它',
        value: 'OTHER'
      }],
      form: {
        targetId: this.targetId,
        targetType: this.targetType,
        reportType: '',
        reason: ''
      }
    }
  },
  created () {
    this.localVisible = this.visible
  },
  watch: {
    visible: function (newValue) {
      this.localVisible = newValue
    }
  },
  methods: {
    handleClose: function () {
      this.localVisible = false
      this.$emit('update:visible', false)
    },
    submitReport: function () {
      if (!this.checkLogin()) {
        return
      }
      if (!this.form.reportType) {
        this.$message({
          message: this.$t('selectReportType'),
          type: 'warning'
        })
        return
      }
      if (!this.form.reason) {
        this.$message({
          message: this.$t('inputReportContent'),
          type: 'warning'
        })
        return
      }
      this.form.targetId = this.targetId
      this.form.targetType = this.targetType
      console.log('提交举报')
      reportApi.addReport(this.form).then(resp => {
        if (resp.httpStatus === 200) {
          this.$message({
            message: this.$t('reportSuccess'),
            type: 'success'
          })
          this.handleClose()
        }
      })
    },
    checkLogin: function () {
      if (!this.$store.state.user || this.$store.state.user.name === '未登录') {
        this.$store.state.loginVisible = true
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>
.report {
  height: 100%;
}

.report-btn {
  display: flex;
  flex-direction: row;
  justify-content: right;
  margin-top: 40px;
  margin-bottom: 20px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.report>>>.el-dialog__wrapper .el-dialog {
  border-radius: 14px;
  padding: 5px;
}
</style>
