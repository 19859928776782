<!--PostComment:Hi, 评论列表 2024/07/14 17:52:31 YuAng-->

<template>
  <div class="post-comment">
    <div class="hot-comment" v-if="hotCommentsCount > 0">
      <p class="title">{{ $t('hotComment') }} {{ hotCommentsCount }} {{ $t('hotTiao') }}</p>
      <div class="bottom-line"></div>
      <composite-chat v-for="(comment, index) in hotComments" :key="'hot_' + index" :item="comment"
        @update-reply="updateReply"></composite-chat>
    </div>
    <div class="all-comment mt-40" v-if="commentsCount > 0">
      <p class="title">{{ $t('commentAll') }} {{ commentsCount }} {{ $t('tiao') }}</p>
      <div class="bottom-line"></div>
      <composite-chat v-for="(comment, index) in comments" :key="'all_' + index" :item="comment"
        @update-reply="updateReply"></composite-chat>
    </div>
  </div>
</template>
<script>
import CompositeChat from '@/views/components/CompositeChat.vue'
export default {
  name: 'PostComment',
  components: {
    CompositeChat
  },
  props: {
    hotCommentsCount: {
      type: Number,
      default: 1
    },
    commentsCount: {
      type: Number,
      default: 1
    },
    hotComments: {
      type: Array,
      default: function () {
        return []
      }
    },
    comments: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
    }
  },
  methods: {
    updateReply: function () {
      this.$emit('update-reply')
    }
  }
}
</script>

<style scoped>
.post-comment {
  background-color: white;
  padding: 30px;
  margin-top: 10px;
}

.bottom-line {
  margin-top: 10px;
  border-bottom: 1px solid rgb(234, 234, 234);
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
</style>
