<!--InputChat:发表评论 2024/07/20 12:49:19 huangmx-->

<template>
  <div class=" input-chat">
    <div class="chat-top">
      <el-upload action="#" :before-upload="beforeUpload" accept="image/jpg,image/jpeg,image/png">
        <el-button :disabled="imgs.length >= 3" size="mini" :loading="uploading">
          <i v-if="!uploading" class="el-icon-upload el-icon--left"></i>
          <span>{{ uploading ? $t('uploading') : $t('addImg') }}</span>
        </el-button>
      </el-upload>
    </div>
    <div class="content">
      <el-input type="textarea" v-model="content" :rows="5" :placeholder="$t('addImgTip')"></el-input>
    </div>
    <div class="chat-bottom">
      <div class="imgs-area">
        <div class="img-item" v-for="(img, index) in imgs" :key="'img_' + index"
          :style="{ backgroundImage: 'url(' + img + ')' }">
          <el-button type="danger" @click="delImg(index)" size="mini" icon="el-icon-delete" circle></el-button>
        </div>
      </div>
      <div class="text-right mt-10">
        <el-button size="mini" type="primary" @click="submitComment">{{ $t('okComment') }}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { uploadFile } from '@/utils/aws'
export default {
  name: 'InputChat',
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      uploading: false,
      uploadProgress: 0,
      content: '',
      imgs: []
    }
  },
  watch: {
    item: {
      handler (newVal, oldVal) {
        this.content = newVal.content
        if (newVal.pictureList && newVal.pictureList.length > 0) {
          newVal.pictureList.forEach(p => {
            this.imgs.push(p)
          })
        } else {
          this.imgs = []
        }
      },
      deep: true
    }
  },
  created () {
    this.content = this.item.content
  },
  methods: {
    beforeUpload: function (file) {
      if (this.uploading) {
        this.$message.warn(this.$t('waiteMoment'))
        return
      }
      const isJPG = /\.(jpe?g|png|gif)$/i.test(file.name)

      if (!isJPG) {
        this.$message.error(this.$t('onlyFormat'))
        return
      }
      this.doUpload(file)
    },
    doUpload: function (file) {
      this.uploading = true
      uploadFile(file, 'front/comment', (loaded, total) => {
        this.uploadProgress = Math.round((loaded / total) * 100)
        console.log(this.uploadProgress)
      }).then(res => {
        this.uploading = false
        this.uploadProgress = 0
        this.imgs.push(res.Location)
      }).catch(error => {
        this.uploading = false
        console.error('Upload failed:', error)
      })
    },
    delImg: function (index) {
      this.imgs.splice(index, 1)
    },
    submitComment: function () {
      this.$emit('submit-comment', { imgs: this.imgs, content: this.content })
      this.$message.success(this.$t('commentSuccess'))
      this.content = ''
      this.imgs = []
    }
  }
}
</script>

<style scoped>
.chat-top {
  margin-bottom: 10px;
  display: flex;
  justify-content: left;
}

.chat-bottom {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.imgs-area {
  max-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.img-item {
  width: 80px;
  height: 80px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 14px;
  margin-right: 20px;
  overflow: hidden;
  position: relative;
}

.text-right {
  text-align: right;
}

.mt-10 {
  margin-top: 10px;
}

.img-item>>>.el-button {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
